#chat {
  text-align: left;
  background: #f1f1f1;
  width: 500px;
  min-height: 300px;
  padding: 20px;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.swapText{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.swapText p{
  margin-top: 0;
  margin-bottom: 10px;
}

#turnsToSwapNumber{
  font-weight: bold;
  font-size: 2em;
}

.alignTextCenter{
  text-align: center;
}

button {
  margin-right: 10px;
  margin-left: 10px;
}